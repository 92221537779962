import React from "react"
import Helmet from "react-helmet"
import LightHeader from "../components/headers/lightHeader"
import FormattedParagraphs from "../components/formattedParagraphs"
import Img from "gatsby-image"
import { Link } from "gatsby"
import { calcVhFromPixels, calcVwFromPixels } from "../helpers/general"
import BackgroundImage from "gatsby-background-image"

export default function SimpleTemplate({ data, pageContext: { pageContent } }) {
  const headerHeight = 32
  const yellowImageId = "f9e7b305-91d3-5391-ae00-43a2db0ecce1"

  const FaintImage = props => {
    if (props.imageData.sanityPage.id === yellowImageId) {
      return null
    }

    // if it's the black square, make opacity 100 pct
    const opacity =
      props.pageData.id === "4c3300b0-764f-5f9d-ae24-0ce92c09b994" ||
      props.pageData.id === "d52dea20-9433-5bb4-b0a3-9989cb20d08d"
        ? 100
        : 50
    return (
      <>
        <Link to="/">
          <div
            className="fixed transform scale-125 pt-24 md:pt-0"
            style={{
              top: calcVhFromPixels(props.pageData.locy),
              left: calcVwFromPixels(props.pageData.locx),
              width: calcVwFromPixels(props.pageData.width),
              mixBlendMode: props.pageData.mix,
              zIndex: props.pageData.zindex ? props.pageData.zindex : 10,
              opacity: opacity + "%",
            }}
          >
            <Img fluid={props.imageData.sanityPage.frontImage.asset.fluid} />
          </div>
        </Link>
      </>
    )
  }

  const YellowBackground = props => {
    console.log(props.imageData.sanityPage.id)
    console.log("hi")
    if (props.imageData.sanityPage.id === yellowImageId) {
      return (
        <BackgroundImage
          backgroundColor={`#ffe066`}
          style={{
            width: "100%",
            backgroundAttachment: "fixed",
          }}
          fluid={data.sanityPage.frontImage.asset.fluid}
        >
          {props.children}
        </BackgroundImage>
      )
    } else {
      return props.children
    }
  }

  return (
    <>
      <div className="w-screen h-screen" style={{ backgroundColor: "#F5F5F5" }}>
        <YellowBackground imageData={data}>
          <Helmet>
            <style>{"body { background-color: #F5F5F5; }"}</style>
            <title>SLOWGLASS</title>
          </Helmet>
          <LightHeader height={headerHeight} />
          <FaintImage pageData={pageContent} imageData={data} />
          <div className="px-8">
            <main className="pt-32 container mx-auto max-w-2xl">
              <FormattedParagraphs
                classes="text-black relative z-50"
                blocks={pageContent._rawMainContent}
              />
            </main>
          </div>
        </YellowBackground>
      </div>
    </>
  )
}

export const pageQuery = graphql`
  query getWhitePage($pageId: String) {
    sanityPage(id: { eq: $pageId }) {
      id
      frontImage {
        asset {
          fluid(maxWidth: 700) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`
